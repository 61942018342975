/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'KG';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/KolektivGroteskIV-Regular.woff2") format("woff2"), url("../fonts/KolektivGroteskIV-Regular.woff") format("woff");
}

@font-face {
  font-family: 'KG';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/KolektivGroteskIV-Italic.woff2") format("woff2"), url("../fonts/KolektivGroteskIV-Italic.woff") format("woff");
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cf:after, header .left:after {
  content: "";
  display: table;
  clear: both;
}

.nowrap {
  white-space: nowrap;
}

html {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  font-size: 100%;
  font-family: "KG", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  min-width: 100%;
  min-height: 100%;
  background-color: white;
}

@media screen and (min-width: 600px) {
  html {
    font-size: 120%;
  }
}

body {
  font-family: "KG", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: inherit;
}

body.black > header {
  color: black;
}

body.black > header .cls-1 {
  fill: transparent;
}

body.black > header .cls-2 {
  fill: black;
}

body.black > header .hamburger-inner, body.black > header .hamburger-inner::before, body.black > header .hamburger-inner::after {
  background-color: black;
}

body.white > header {
  color: white;
}

body.white > header .cls-1 {
  fill: transparent;
}

body.white > header .cls-2 {
  fill: white;
}

html.nav-active, html.nav-active body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

html.nav-active #main-nav {
  opacity: 1;
  pointer-events: all;
}

html.nav-active body > header {
  color: white;
}

html.nav-active body > header .cls-1 {
  fill: transparent;
}

html.nav-active body > header .cls-2 {
  fill: white;
}

html.nav-active body > header .hamburger-inner, html.nav-active body > header .hamburger-inner::before, html.nav-active body > header .hamburger-inner::after {
  background-color: white;
}

header {
  z-index: 100;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  width: calc(100% - 40px);
  color: white;
  display: flex;
  height: 79px;
  justify-content: space-between;
}

header img, header #suturelogo {
  height: 100%;
}

header h1 {
  font-size: 55px;
  margin-top: 8px;
  line-height: 0.7;
  letter-spacing: -1.5px;
  display: block;
}

header .left {
  display: flex;
}

header .left * {
  margin-right: 10px;
}

main#content {
  margin-top: 139px;
}

main#content em {
  font-style: italic;
}

main#content p, main#content h1, main#content h2, main#content h3, main#content h4, main#content h5 {
  padding: 0 5%;
}

@media screen and (min-width: 600px) {
  main#content p, main#content h1, main#content h2, main#content h3, main#content h4, main#content h5 {
    padding: 0 20%;
  }
}

main#content h1 {
  font-size: 4.2rem;
  line-height: 0.92;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 2rem;
}

main#content h2 {
  font-size: 2.1rem;
  margin-bottom: 2rem;
}

main#content h3 {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 2rem;
}

main#content a {
  text-decoration: underline;
}

main#content p {
  margin-bottom: 2rem;
}

main#content figure.video {
  max-width: auto;
  padding: 0 5%;
}

@media screen and (min-width: 600px) {
  main#content figure.video {
    padding: 0 20%;
  }
}

main#content figure.video .fluidvids {
  height: 350px;
  height: 36vw;
}

main#content figure {
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 2rem;
}

main#content figure img {
  width: 100%;
}

main#content figure.middle {
  padding: 0 5%;
}

@media screen and (min-width: 600px) {
  main#content figure.middle {
    padding: 0 20%;
  }
}

section#contact {
  position: fixed;
  left: 20px;
  bottom: 20px;
  color: white;
}

#main-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #3f3f3f;
  padding: 0 2vw;
  overflow-y: auto;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
}

#main-nav h2 {
  color: #00ffff;
}

#main-nav a:hover {
  color: yellow;
}

#main-nav nav {
  margin-top: 139px;
  width: 100%;
  text-align: center;
  font-size: 2.1rem;
  letter-spacing: -0.02em;
  color: white;
}

#main-nav nav a {
  display: block;
}

.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 1;
}

.hamburger-box {
  width: 79px;
  height: 79px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -12.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 79px;
  height: 25px;
  background-color: white;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -27px;
}

.hamburger-inner::after {
  bottom: -27px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
