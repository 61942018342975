@import '_reset';
@import '_media-queries';
// @import 'hamburgers/hamburgers'; 

@font-face {
	font-family: 'KG';
	font-style: normal;
	font-weight: 400;
	src:	url('../fonts/KolektivGroteskIV-Regular.woff2') format('woff2'),
			url('../fonts/KolektivGroteskIV-Regular.woff') format('woff');
}

@font-face {
	font-family: 'KG';
	font-style: italic;
	font-weight: 400;
	src:	url('../fonts/KolektivGroteskIV-Italic.woff2') format('woff2'),
			url('../fonts/KolektivGroteskIV-Italic.woff') format('woff');
}


$sans: 'KG', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$margin: 20px;
$headerHeight: 79px;

$grey: #3f3f3f;
$menucolor: #00ffff;

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.cf {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.nowrap {
	white-space: nowrap;
}

html {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	font-size: 100%;
	font-family: $sans;
	font-weight: 400;
	min-width: 100%;
    min-height: 100%;
	@include min-screen(600px) {
		font-size: 120%;
	} 
	background-color: white;
	
}

body {
	font-family: $sans;
	width: 100%;
    height: 100%;
	font-size: 1rem;
	line-height: 1.3;
	-webkit-font-smoothing: antialiased;
}

a {
	text-decoration: none;
	color: inherit;
}

h1, h2, h3, h4, h5, h6 {

}

body.black > header {
	color: black;
	.cls-1{fill:transparent;}
	.cls-2{fill:black;}
	.hamburger-inner {
		&,
		&::before,
		&::after { 
			background-color: black; 
		}
	}
}
body.white > header {
	color: white;
	.cls-1{fill:transparent;}
	.cls-2{fill:white;}
}

html.nav-active {
	&, body {margin: 0; height: 100%; overflow: hidden;}
	#main-nav {
		opacity: 1;
		pointer-events: all;
	}
	body > header {
		color: white;
		.cls-1{fill:transparent;}
		.cls-2{fill:white;}

		.hamburger-inner {
			&,
			&::before,
			&::after { 
				background-color: white; 
			}
		}
	}
}


header {
	z-index: 100;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	margin: $margin;
	width: calc(100% - #{2 * $margin});
	color: white;
	display: flex;  
	height: $headerHeight;
	justify-content: space-between;

	
	img, #suturelogo {
		height: 100%;
	}

	h1 {

		font-size: 55px;
		margin-top: 8px;
		line-height: 0.7;
		letter-spacing: -1.5px;
		display: block;
	}

	.left {
		display: flex;
		@extend .cf;
		* {
			margin-right: 10px; 
		}
	}
	.lang {

	}
}


main#content {

	margin-top: $margin + $margin + $margin + $headerHeight;

	em {
		font-style: italic;
	}

	p, h1, h2, h3, h4, h5 {
		padding: 0 5%;
		@include min-screen(600px) {
			padding: 0 20%;
		} 
	}

	h1 {
		font-size: 4.2rem;
		line-height: 0.92;
		letter-spacing: -0.03em;
		text-align: center;
		margin-bottom: 2rem;
	}

	h2 {
		font-size: 2.1rem;
		margin-bottom: 2rem;
	}

	h3 {
		font-size: 1.3rem;
		text-align: center;
		margin-bottom: 2rem;
	}

	a {
		text-decoration: underline;
	}


	p {
		margin-bottom: 2rem;
	}
	figure.video {
		max-width: auto;
		padding: 0 5%;
		@include min-screen(600px) {
			padding: 0 20%;
		} 
		.fluidvids {
			height: 350px;
			height: 36vw;
		}
	}

	figure {
		max-width: 100%;
		overflow: hidden;
		margin-bottom: 2rem;
		img {
			width: 100%;
		}
		&.middle {
			padding: 0 5%;
			@include min-screen(600px) {
				padding: 0 20%;
			} 
		}
	}

}


section#contact {
	position:fixed;
	left: $margin;
	bottom: $margin;
	color: white;
}



#main-nav {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $grey;
	padding:0 2vw;
	overflow-y: auto;
	top:0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s; 


	h2 {
		color: $menucolor;
	}
	a:hover {
		color: yellow;
	}
	nav {
		margin-top: $margin + $margin  + $margin + $headerHeight;
		width: 100%;
		text-align: center;
		font-size: 2.1rem;
		letter-spacing: -0.02em;
		color: white;

		a {
			display: block;
		}
	}
}


// hamburger

// Settings
// ==================================================
$hamburger-padding-x                       : 0 !default;
$hamburger-padding-y                       : 0 !default;
$hamburger-layer-width                     : $headerHeight !default;
$hamburger-layer-height                    : 25px !default;
$hamburger-layer-spacing                   : 2px !default;
$hamburger-layer-color                     : white !default;
$hamburger-layer-border-radius             : 0px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;


.hamburger {
	padding: $hamburger-padding-y $hamburger-padding-x;
	display: inline-block;
	cursor: pointer;
	outline: none;
  
	transition-property: opacity, filter;
	transition-duration: $hamburger-hover-transition-duration;
	transition-timing-function: $hamburger-hover-transition-timing-function;
  
	// Normalize (<button>)
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
  
	&:hover {
	  @if $hamburger-hover-use-filter == true {
		filter: $hamburger-hover-filter;
	  }
	  @else {
		opacity: $hamburger-hover-opacity;
	  }
	}
  }
  
  .hamburger-box {
	width: $hamburger-layer-width;
	height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
	// height: calc($hamburger-layer-height * 3 + $hamburger-layer-spacing * 2);
	display: inline-block;
	position: relative;
  }
  
  .hamburger-inner {
	display: block;
	top: 50%;
	margin-top: $hamburger-layer-height / -2;
  
	&,
	&::before,
	&::after {
	  width: $hamburger-layer-width;
	  height: $hamburger-layer-height;
	  background-color: $hamburger-layer-color;
	  border-radius: $hamburger-layer-border-radius;
	  position: absolute;
	  transition-property: transform;
	  transition-duration: 0.15s;
	  transition-timing-function: ease;
	}
  
	&::before,
	&::after {
	  content: "";
	  display: block;
	}
  
	&::before {
	  top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
  
	&::after {
	  bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
  }
  



.hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease,
                    opacity 0.075s ease;
      }  

      &::after {
        transition: bottom 0.075s 0.12s ease,
                    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease,
                      opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
                      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
